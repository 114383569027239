export function sanitizeCityName(s: string): string {
  if (!s) {
    return s;
  }

  return s
    .toLowerCase()
    .replace(/[^a-zA-Z. ]/g, '')
    .trim()
    .replace(/ /g, '-');
}
