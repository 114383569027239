import Router from 'next/router';
import { format } from 'date-fns';

export function sendLoginLink(url: string, username: string) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  if (username === undefined || username === '') {
    return;
  }
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
      email: username,
      dateTime: `${format(new Date(), 'dd MMM, HH:mm:ss')} ${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
    }),
  };

  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return Router.push('/auth/login-email-sent');
      }
      if (response.status === 404) {
        return Router.push('/auth/user-not-found');
      } else {
        return Router.push('/errors/unexpected-error');
      }
    })
    .catch((error) => console.log('Login error', error));
}
